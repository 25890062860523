<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">
        {{ editing ? "Editar impulsador" : "Nuevo impulsador" }}
      </h1>
    </div>
    <div>
      <form class="custom-form" @submit.prevent="save" novalidate>
        <b-card
          tag="article"
          no-body
          style="max-width: 760px"
          class="custom-card"
        >
          <b-card-header
            header-tag="section"
            class="custom-card-header d-flex align-items-center"
          >
            <h3>
              {{
                editing ? "Datos del impulsador" : "Datos del nuevo impulsador"
              }}
            </h3>
          </b-card-header>
          <b-card-body class="custom-card-body p-0">
            <section class="form-section">
              <div>
                <h2 class="form-subtitle">Datos personales</h2>
                <p class="form-description">
                  Ingresa los datos personales del proveedor
                </p>
              </div>
              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group">
                  <label for="identifierType">Tipo de documento * </label>
                  <FormSelect
                    id="identifierType"
                    v-model="pusher.identifierType"
                    :items="identifierTypes"
                    defaultOption="Elegir tipo de documento"
                    :showError="
                      $v.pusher.identifierType.$error &&
                      !$v.pusher.identifierType.required
                    "
                  />
                  <FormError
                    v-if="
                      $v.pusher.identifierType.$error &&
                      !$v.pusher.identifierType.required
                    "
                    message="Tipo de documento es requerido"
                  />
                </div>

                <div class="form-group">
                  <label for="identifier">Número de documento *</label>
                  <input
                    id="identifier"
                    type="text"
                    :maxlength="maxLengthIdentifier"
                    v-model.trim="pusher.identifier"
                    autocomplete="off"
                    placeholder="Ingresar número de documento"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.pusher.identifier.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.pusher.identifier.$error &&
                      !$v.pusher.identifier.required
                    "
                    message="Número de documento es requerido"
                  />
                  <FormError
                    v-if="
                      $v.pusher.identifier.$error &&
                      !$v.pusher.identifier.numeric
                    "
                    message="Número de documento debe tener un valor numérico"
                  />
                </div>
              </div>
              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group">
                  <label for="firstName">Nombres *</label>
                  <input
                    id="firstName"
                    type="text"
                    maxlength="200"
                    v-model.trim="pusher.firstName"
                    autocomplete="off"
                    placeholder="Ingresar nombres"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.pusher.firstName.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.pusher.firstName.$error &&
                      !$v.pusher.firstName.required
                    "
                    message="Nombre es requerido"
                  />
                </div>

                <div class="form-group">
                  <label for="lastName">Apellidos *</label>
                  <input
                    id="lastName"
                    type="text"
                    maxlength="200"
                    v-model.trim="pusher.lastName"
                    autocomplete="off"
                    placeholder="Ingresar apellidos"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.pusher.lastName.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.pusher.lastName.$error && !$v.pusher.lastName.required
                    "
                    message="Apellido es requerido"
                  />
                </div>
              </div>

              <div class="custom-form-row">
                <div class="form-group">
                  <label for="address">Dirección domiciliaria</label>
                  <input
                    id="address"
                    type="text"
                    v-model.trim="pusher.address"
                    autocomplete="off"
                    placeholder="Ingresar dirección domiciliaria"
                    class="custom-form-control"
                  />
                </div>
              </div>

              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group">
                  <label for="mobile">Celular</label>
                  <input
                    id="mobile"
                    type="text"
                    v-model.trim="pusher.mobile"
                    autocomplete="off"
                    placeholder="Ingresar celular"
                    maxlength="9"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.pusher.mobile.$error,
                    }"
                  />
                  <FormError
                    v-if="$v.pusher.mobile.$error && !$v.pusher.mobile.numeric"
                    message="Celular debe ser un valor numérico"
                  />
                  <FormError
                    message="Ingresa un celular válido de 9 dígitos"
                    v-if="$v.pusher.mobile.$error && !$v.pusher.mobile.mobile"
                  ></FormError>
                </div>
                <div class="form-group">
                  <label for="email">Correo electrónico</label>
                  <input
                    id="email"
                    type="text"
                    v-model.trim="pusher.email"
                    autocomplete="off"
                    placeholder="Ingresar correo electrónico"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.pusher.email.$error,
                    }"
                  />
                  <FormError
                    v-if="$v.pusher.email.$error && !$v.pusher.email.email"
                    message="Ingresa un correo electrónico válido"
                  />
                </div>
              </div>

              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group">
                  <label for="bank">Proyecto</label>
                  <FormSelect
                    id="bank"
                    v-model="pusher.projectTypeId"
                    :items="projectTypes"
                    defaultOption="Elegir proyecto"
                  />
                </div>
                <div class="form-group">
                  <label for="bank">Zonal</label>
                  <FormSelect
                    id="bank"
                    v-model="pusher.zonalId"
                    :items="zonalList"
                    defaultOption="Elegir zonal"
                  />
                </div>
              </div>
            </section>

            <section class="form-section">
              <div>
                <h2 class="form-subtitle">Datos de facturación</h2>
                <p class="form-description">
                  Ingresa el RUC del proveedor que realizará la facturación.
                  Recuerda que el proveedor debe estar creado previamente
                </p>
              </div>

              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group">
                  <label for="companyId">RUC *</label>
                  <input
                    id="companyId"
                    maxlength="11"
                    type="text"
                    v-model.trim="pusher.companyId"
                    autocomplete="off"
                    placeholder="Ingresar RUC"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.pusher.companyId.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.pusher.companyId.$error &&
                      !$v.pusher.companyId.required
                    "
                    message="RUC es requerido"
                  />
                  <FormError
                    v-if="
                      $v.pusher.companyId.$error && !$v.pusher.companyId.numeric
                    "
                    message="RUC debe tener un valor numérico"
                  />
                  <FormError
                    v-if="
                      $v.pusher.companyId.$error &&
                      !$v.pusher.companyId.minLength
                    "
                    message="RUC debe tener 11 dígitos"
                  />
                  <div
                    class="pushers-count"
                    v-if="supplier.pushersCount != null"
                  >
                    <p class="my-0">
                      Número de impulsadores anexados:
                      {{ supplier.pushersCount }}
                    </p>
                  </div>
                </div>

                <div class="form-group">
                  <label for="companyId">Razón social *</label>
                  <input
                    id="companyName"
                    maxlength="200"
                    disabled
                    type="text"
                    v-model.trim="pusher.companyName"
                    autocomplete="off"
                    placeholder="Razón social"
                    class="custom-form-control"
                  />
                </div>
              </div>
            </section>

            <section class="form-section">
              <div>
                <h2 class="form-subtitle">Datos del contrato</h2>
                <p class="form-description">
                  Ingresa los datos contractuales del proveedor
                </p>
              </div>

              <div class="custom-form-row custom-form-row--col-2">
                <div class="form-group d-flex flex-column">
                  <label for="admissionDate">Fecha de incorporación</label>

                  <FormDatepicker
                    label-id="admissionDate"
                    :show-calendar="showAdmissionDate"
                    :max="new Date()"
                    @show="showAdmissionDate = true"
                    @hidden="showAdmissionDate = false"
                    v-model="pusher.admissionDate"
                  />
                </div>

                <div class="form-group d-flex flex-column">
                  <label for="contractFinishDate"
                  >Fecha de finalización del último contrato</label
                  >

                  <FormDatepicker
                    label-id="contractFinishDate"
                    :show-calendar="showContractFinishDate"
                    @show="showContractFinishDate = true"
                    @hidden="showContractFinishDate = false"
                    v-model="pusher.contractFinishDate"
                  />
                </div>
              </div>
              <div class="custom-form-row">
                <div class="form-group">
                  <label for="managementType"
                  >Subir contrato en formato PDF</label
                  >
                  <FileInput
                    class="w-100 mt-2"
                    id-file="contract"
                    :acceptFile="['.pdf']"
                    @onChange="onContractChange"
                  />
                </div>
              </div>

              <section class="form-group d-flex align-items-center" v-if="false">
                <label for="fingerSensor" class="pt-2 mr-3 checkbox-label"
                >Huellero</label
                >
                <b-form-checkbox
                  id="fingerSensor"
                  v-model="pusher.fingerSensor"
                  name="check-button"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </section>
              <div v-if="pusher.fingerSensor">
                <div class="custom-form-row custom-form-row--col-2">
                  <div class="form-group">
                    <label for="equipmentSerial">Serie de huellero</label>
                    <input
                      id="equipmentSerial"
                      type="text"
                      maxlength="100"
                      v-model.trim="pusher.equipmentSerial"
                      autocomplete="off"
                      placeholder="Ingresar serie de huellero"
                      class="custom-form-control"
                    />
                  </div>

                  <div class="form-group">
                    <label for="equipmentModel">Modelo de huellero</label>
                    <input
                      id="equipmentModel"
                      type="text"
                      maxlength="100"
                      v-model.trim="pusher.equipmentModel"
                      autocomplete="off"
                      placeholder="Ingresar modelo de huellero"
                      class="custom-form-control"
                    />
                  </div>
                </div>

                <div class="custom-form-row custom-form-row--col-2">
                  <div class="form-group d-flex flex-column">
                    <label for="equipmentAssigmentDate">Fecha de entrega</label>
                    <FormDatepicker
                      label-id="equipmentAssigmentDate"
                      :max="new Date()"
                      :show-calendar="showEquipmentAssigmentDate"
                      @show="showEquipmentAssigmentDate = true"
                      @hidden="showEquipmentAssigmentDate = false"
                      v-model="pusher.equipmentAssigmentDate"
                    />
                  </div>
                  <div class="form-group d-flex flex-column">
                    <label for="equipmentSignatureDate"
                    >Fecha de firma de comodato</label
                    >
                    <FormDatepicker
                      label-id="equipmentSignatureDate"
                      :max="new Date()"
                      :show-calendar="showEquipmentSignatureDate"
                      @show="showEquipmentSignatureDate = true"
                      @hidden="showEquipmentSignatureDate = false"
                      v-model="pusher.equipmentSignatureDate"
                    />
                  </div>
                </div>

                <div class="custom-form-row">
                  <div class="form-group">
                    <label for="managementType"
                    >Subir contrato de comodato en PDF</label
                    >
                    <FileInput
                      class="w-100 mt-2"
                      id-file="loan"
                      :acceptFile="['.pdf']"
                      @onChange="onLoanChange"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group-buttons text-right">
                <router-link
                  :to="{ name: 'config-pusher-list' }"
                  class="button button-light"
                >
                  {{ editing ? "Volver" : "Cancelar" }}
                </router-link>
                <button class="button button-primary" type="submit">
                  Guardar
                </button>
              </div>
            </section>
          </b-card-body>
        </b-card>
      </form>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  FormSelect,
  FormError,
  FileInput,
  FormDatepicker,
  CustomValidators,
} from "wize-admin";
import {
  ProjectTypeService,
  PusherService,
  SupplierService,
  ZonalService,
} from "@/core/services";
import { email, minLength, numeric, required } from "vuelidate/lib/validators";
import { Constants as LocalConstants } from "@/core/utils";

export default {
  components: {
    FormError,
    FileInput,
    FormSelect,
    FormDatepicker,
  },
  data() {
    return {
      loading: true,
      pusher: {
        firstName: null,
        lastName: null,
        identifierType: null,
        identifier: null,
        address: null,
        mobile: null,
        email: null,
        companyId: null,
        companyName: null,
        projectTypeId: null,
        zonalId: null,
        admissionDate: null,
        contractFinishDate: null,
        contractFile: null,
        equipmentSerial: null,
        equipmentModel: null,
        equipmentAssigmentDate: null,
        equipmentSignatureDate: null,
        fingerSensor: false,
        loanFile: null,
        supplierId: null,
      },
      supplier: {
        pushersCount: null,
      },
      editing: false,
      identifierTypes: [
        LocalConstants.IdentifierType.DNI,
        LocalConstants.IdentifierType.CE,
        LocalConstants.IdentifierType.PASAPORTE,
        LocalConstants.IdentifierType.CARNE_REFUGIO,
        LocalConstants.IdentifierType.CARNE_IDENTIDAD,
        LocalConstants.IdentifierType.CARNE_PERMANENCIA,
        LocalConstants.IdentifierType.DNI_EXTRANJERO,
      ],
      showAdmissionDate: false,
      showContractFinishDate: false,
      showEquipmentAssigmentDate: false,
      showEquipmentSignatureDate: false,
      projectTypes: [],
      zonalList: [],
    };
  },
  validations: {
    pusher: {
      firstName: {required},
      lastName: {required},
      identifierType: {required},
      identifier: {required, numeric},
      mobile: {numeric, mobile: CustomValidators.mobile},
      email: {email},
      companyId: {required, numeric, minLength: minLength(11)},
    },
  },
  watch: {
    "$route.params.pusherId": function () {
      if (this.$route.params.pusherId) {
        this.load(this.$route.params.pusherId);
      } else {
        this.loading = false;
      }
    },
    "pusher.identifierType": function () {
      this.pusher.identifier = null;
    },
    "pusher.identifier": async function (val) {
      if (this.loading) return;
      this.$v.pusher.identifier.$touch();
      if (this.$v.pusher.identifier.$error) return;
      if (this.pusher.identifierType === LocalConstants.IdentifierType.DNI.key && this.pusher.identifier && this.pusher.identifier.length === 8) {
        try {
          this.$store.dispatch("app/loading", true);
          const resp = (await PusherService.queryDni(val)).payload;
          this.pusher.firstName = resp.firstName;
          this.pusher.lastName = resp.lastName;
        } catch (ex) {
          Alert.error(ex.errors.message);
          console.error(ex);
        } finally {
          this.$store.dispatch("app/loading", false);
        }
      }
    },
    "pusher.companyId": async function (val) {
      this.$v.pusher.companyId.$touch();
      if (this.$v.pusher.companyId.$error) return;
      try {
        const response = await SupplierService.getByCompanyId(val);
        const supplier = response.payload;
        this.pusher.companyName = supplier.companyName;
        this.pusher.supplierId = supplier.id;
        this.supplier.pushersCount = supplier.pushersCount;
      } catch (ex) {
        this.pusher.companyId = null;
        this.pusher.supplierId = null;
        this.pusher.companyName = null;
        this.supplier.pushersCount = null;
        Alert.error("No se encontró proveedor");
        console.error(ex);
      }
    },
  },
  computed: {
    maxLengthIdentifier: function () {
      if (this.pusher.identifierType) {
        const type = this.identifierTypes.find(
          (i) => i.key === this.pusher.identifierType
        );
        return type.length;
      }
      return 11;
    },
  },
  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$error) return;
      try {
        this.$store.dispatch("app/loading", true);
        const data = {...this.pusher};
        if (!this.pusher.contractFile) delete data.contractFile;
        if (!this.pusher.loanFile) delete data.loanFile;

        Object.keys(data).forEach((key) => {
          if (data[key] === null || typeof data[key] === "undefined") {
            delete data[key];
          }
        });

        let response;
        if (!this.editing) response = await PusherService.save(data);
        else
          response = await PusherService.update(
            this.$route.params.pusherId,
            data
          );
        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-pusher-edit",
            params: {pusherId: response.payload.id},
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(pusherId) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await PusherService.get(pusherId);
        const payload = response.payload;
        this.pusher.firstName = payload.firstName;
        this.pusher.lastName = payload.lastName;
        this.pusher.identifierType = payload.identifierType;
        this.pusher.identifier = payload.identifier;
        this.pusher.address = payload.address;
        this.pusher.mobile = payload.mobile;
        this.pusher.email = payload.email;
        this.pusher.admissionDate = payload.admissionDate;
        this.pusher.contractFinishDate = payload.contractFinishDate;
        this.pusher.contractFile = payload.contractFile;
        this.pusher.equipmentSerial = payload.equipmentSerial;
        this.pusher.equipmentModel = payload.equipmentModel;
        this.pusher.equipmentAssigmentDate = payload.equipmentAssigmentDate;
        this.pusher.equipmentSignatureDate = payload.equipmentSignatureDate;
        this.pusher.fingerSensor = payload.fingerSensor;
        this.pusher.loanFile = payload.loanFile;
        if (payload.projectType)
          this.pusher.projectTypeId = payload.projectType.id;

        if (payload.zonal) this.pusher.zonalId = payload.zonal.id;

        if (payload.supplier) {
          this.pusher.supplierId = payload.supplier.id;
          this.pusher.companyId = payload.supplier.companyId;
          this.pusher.companyName = payload.supplier.companyName;
        }

        if (payload.pusherEquipment) {
          this.pusher.equipmentAssigmentDate =
            payload.pusherEquipment.assigmentDate;
          this.pusher.equipmentSignatureDate =
            payload.pusherEquipment.signatureDate;
          if (payload.pusherEquipment.equipment) {
            const equipment = payload.pusherEquipment.equipment;
            this.pusher.equipmentSerial = equipment.serial;
            this.pusher.equipmentModel = equipment.model;
          }
        }
        this.$nextTick(() => {
          this.pusher.identifier = payload.identifier;
        });
        this.editing = true;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
        this.$router.push({name: "config-pusher-list"});
      } finally {
        this.$store.dispatch("app/loading", false);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
    onContractChange(file) {
      this.pusher.contractFile = file;
    },
    onLoanChange(file) {
      this.pusher.loanFile = file;
    },
  },
  async created() {
    const zonalList = (await ZonalService.listActive()).payload;
    zonalList.forEach((b) =>
      this.zonalList.push({key: b.id, label: b.description})
    );

    const projectTypes = (await ProjectTypeService.listActive()).payload;
    projectTypes.forEach((b) =>
      this.projectTypes.push({key: b.id, label: b.description})
    );
    if (this.$route.params.pusherId) {
      this.load(this.$route.params.pusherId);
    } else {
      this.loading = false;
    }
  },
};
</script>

<style lang="stylus" scoped>
@import "~wize-admin/dist/styles/variables"
@import "~wize-admin/dist/styles/mixins"

.pushers-count
  margin-top 5px
  min-height: 22px
  background-color: color-light-search-bar
  display: inline-block
  padding: 4px 10px
  border-radius: 18px

  p
    font: roboto-regular
    font-size: 12px;
    color: color-light-grey
</style>
