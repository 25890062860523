var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container project-type-editor"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.editing ? "Editar proyecto" : "Nuevo proyecto")+" ")])]),_c('div',[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"640px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"card-header custom-card-header",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.editing ? "Datos del proyecto" : "Datos del nuevo proyecto")+" ")])]),_c('b-card-body',{staticClass:"custom-card-body p-0"},[_c('form',{staticClass:"custom-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('section',{staticClass:"form-section"},[_c('div',[_c('h2',{staticClass:"form-subtitle"},[_vm._v("Información del proyecto")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Descripción *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.description),expression:"form.description",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                  'custom-form-control-error': _vm.$v.form.description.$error,
                },attrs:{"id":"description","type":"text","autocomplete":"off","maxlength":"100","placeholder":"Ingresar descripción"},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "description", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                  _vm.$v.form.description.$error && !_vm.$v.form.description.required
                )?_c('FormError',{attrs:{"message":"Descripción es requerida"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"prefix"}},[_vm._v("Prefijo *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.prefix),expression:"form.prefix",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                  'custom-form-control-error': _vm.$v.form.prefix.$error,
                },attrs:{"id":"prefix","type":"text","autocomplete":"off","maxlength":"5","placeholder":"Ingresar prefijo"},domProps:{"value":(_vm.form.prefix)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "prefix", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.form.prefix.$error && !_vm.$v.form.prefix.required)?_c('FormError',{attrs:{"message":"Prefijo es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"prefix"}},[_vm._v("Supervisor")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.supervisor),expression:"form.supervisor",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                  'custom-form-control-error': _vm.$v.form.supervisor.$error,
                },attrs:{"id":"prefix","type":"text","autocomplete":"off","placeholder":"Ingresar correo electrónico de supervisor"},domProps:{"value":(_vm.form.supervisor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "supervisor", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.form.supervisor.$error && !_vm.$v.form.supervisor.email)?_c('FormError',{attrs:{"message":"Correo electrónico de supervisor es requerido"}}):_vm._e()],1)]),_c('section',{staticClass:"form-section"},[_c('div',[_c('h2',{staticClass:"form-subtitle"},[_vm._v("Servicio contable")])]),_c('div',{staticClass:"form-group d-flex flex-column flex-md-row"},[_c('label',{staticClass:"label",attrs:{"for":"payedAmount"}},[_vm._v(" Aplicar descuento por gestión contable.")]),_c('div',{staticClass:"flex-1 ml-3",staticStyle:{"margin-top":"-4px"}},[_c('b-form-checkbox',{attrs:{"id":"hasRetention","name":"check-button","switch":"","size":"lg"},model:{value:(_vm.form.applyAccountingDiscount),callback:function ($$v) {_vm.$set(_vm.form, "applyAccountingDiscount", $$v)},expression:"form.applyAccountingDiscount"}})],1)]),(_vm.form.applyAccountingDiscount)?_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"accountingDiscount"}},[_vm._v("Porcentaje de descuento *")]),_c('div',{staticClass:"flex-1"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.accountingDiscount),expression:"form.accountingDiscount",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
              'custom-form-control-error': _vm.$v.form.accountingDiscount.$error,
            },attrs:{"id":"accountingDiscount","type":"text","autocomplete":"off","maxlength":"11","placeholder":"Ingresar descuento por gestión contable"},domProps:{"value":(_vm.form.accountingDiscount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "accountingDiscount", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.form.accountingDiscount.$error && !_vm.$v.form.accountingDiscount.required)?_c('FormError',{attrs:{"message":"Descuento por gestión contable es requerido"}}):_vm._e(),(_vm.$v.form.accountingDiscount.$error && !_vm.$v.form.accountingDiscount.integer)?_c('FormError',{attrs:{"message":"El descuento por gestión contable debe ser un valor numérico"}}):_vm._e(),(_vm.$v.form.accountingDiscount.$error && (!_vm.$v.form.accountingDiscount.minValue || !_vm.$v.form.accountingDiscount.maxValue))?_c('FormError',{attrs:{"message":"El descuento por gestión contable debe ser mayor a 0 y menor a 100"}}):_vm._e()],1)]):_vm._e()]),_c('section',{staticClass:"form-section"},[_c('div',[_c('h2',{staticClass:"form-subtitle"},[_vm._v("Tags de pago")])]),_c('div',{staticClass:"form-group mb-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"paymentType"}},[_vm._v("Código *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.paymentTypeForm.key),expression:"paymentTypeForm.key"}],staticClass:"custom-form-control",class:{
                        'custom-form-control-error':
                          _vm.$v.paymentTypeForm.key.$error &&
                          !_vm.$v.paymentTypeForm.key.required,
                      },attrs:{"id":"paymentType","type":"text","autocomplete":"off","placeholder":"Ingresar código"},domProps:{"value":(_vm.paymentTypeForm.key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.paymentTypeForm, "key", $event.target.value)}}}),(
                        _vm.$v.paymentTypeForm.key.$error &&
                        !_vm.$v.paymentTypeForm.key.required
                      )?_c('FormError',{attrs:{"message":"Código es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"paymentTypeDescription"}},[_vm._v("Descripción *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.paymentTypeForm.label),expression:"paymentTypeForm.label"}],staticClass:"custom-form-control",class:{
                        'custom-form-control-error':
                          _vm.$v.paymentTypeForm.label.$error &&
                          !_vm.$v.paymentTypeForm.label.required,
                      },attrs:{"id":"paymentTypeDescription","type":"text","autocomplete":"off","placeholder":"Ingresar descripción"},domProps:{"value":(_vm.paymentTypeForm.label)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.paymentTypeForm, "label", $event.target.value)}}}),(
                        _vm.$v.paymentTypeForm.label.$error &&
                        !_vm.$v.paymentTypeForm.label.required
                      )?_c('FormError',{attrs:{"message":"Descripción es requerida"}}):_vm._e()],1),_c('div',{staticClass:"form-group mb-0 text-right"},[_c('button',{staticClass:"button button-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.addPaymentType()}}},[_vm._v(" Agregar a la lista ")])]),(!_vm.$v.form.tags.$pending && _vm.$v.form.tags.$error)?_c('FormError',{staticClass:"mt-3",attrs:{"message":"Tags son requeridos"}}):_vm._e()],1),_c('div',{staticClass:"col-md-7 mt-3 mt-md-0"},[(!_vm.form.tags.length)?_c('div',{staticClass:"project-type-no-results mt-2 py-2"},[_c('p',{staticClass:"label-description my-0"},[_vm._v(" Añadir elementos para ordenarlos ")])]):_c('div',{staticClass:"mt-2"},[_c('nested-draggable',{attrs:{"list":_vm.form.tags}})],1)])])]),_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-light",attrs:{"to":{ name: 'config-project-type-list' }}},[_vm._v(" "+_vm._s(_vm.editing ? "Volver" : "Cancelar")+" ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])],1)])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }