import {http} from '@/http'
import { Util } from "wize-admin"

export default {
  list(data, params) {
    return http.post('/payments/list', data, {params})
  },
  download(data) {
    return http.post('/payments/xlsx', data,  {responseType: 'blob'})
  },
  downloadMacroBank(data) {
    return http.post('/payments/macrobank/xlsx', data,  {responseType: 'blob'})
  },
  downloadAccounting(data) {
    return http.post('/payments/accounting/xlsx', data,  {responseType: 'blob'})
  },
  getPayment(id) {
    return http.get(`/payments/${id}`)
  },
  changeStatus(paymentId, data){
    return http.put(`/payments/${paymentId}/status`, data)
  },
  changeStatusWithForm(paymentId, data){
    const formData = Util.getFormData(data);
    return http.put(`/payments/${paymentId}/status/form`, formData, {headers: { "Content-Type": "multipart/form-data" },})
  },
  uploadVoucher(data) {
    const formData = Util.getFormData(data);
    return http.post('/payments/vouchers/upload', formData, {headers: { "Content-Type": "multipart/form-data" },})
  },
  listIdentifiers() {
    return http.get('/payments/identifiers')
  },
  downloadPaidDetailTemplate() {
    return http.get('/payments/paid-detail/template/xlsx', {responseType: 'blob'})
  },
}
