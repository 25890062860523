import {http} from '@/http'
import { Util } from "wize-admin";

export default {
  list(data, params) {
    return http.post('/pushers/list', data, {params})
  },
  changeStatus(pusherId, status){
    return http.put(`/pushers/${pusherId}/status`, null, {params: {status}})
  },
  recoverPassword(pusherId){
    return http.put(`/pushers/${pusherId}/recover-password`)
  },
  save(data){
    const formData = Util.getFormData(data);
    return http.post('/pushers', formData, {headers: { "Content-Type": "multipart/form-data" },})
  },
  update(pusherId, data){
    const formData = Util.getFormData(data);
    return http.put(`/pushers/${pusherId}`, formData, {headers: { "Content-Type": "multipart/form-data" },})
  },
  get(pusherId){
    return http.get(`/pushers/${pusherId}`)
  },
  download(data) {
    return http.post('/pushers/xlsx', data,  {responseType: 'blob'})
  },
  queryDni(dni) {
    return http.get(`/pushers/dni/${dni}`)
  },
}
