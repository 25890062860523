<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    size="lg"
    id="modal-payment-cancel"
  >
    <div class="modal-header d-flex justify-content-between align-items-center">
      <span class="modal-title">Anular el pago</span>
      <b-button class="modal-close-icon" @click="hideModal" aria-label="Close">
        <i class="ri-close-large-line"></i>
      </b-button>
    </div>

    <section class="modal-body-container">
      <simple-alert
        text="Para actualizar el pago, por favor ingrese la siguiente información:"
        type="light-gray"
        class="mt-2"
      >
        <template slot="icon">
          <span class="modal-icon mr-2">
            <i class="ri-error-warning-line"></i>
          </span>
        </template>
      </simple-alert>

      <form
        action=""
        @submit.prevent="confirm"
        class="custom-form mt-4"
        novalidate
      >
        <div class="form-group d-flex flex-column flex-md-row">
          <label for="requestDate" class="label">Fecha de solicitud *</label>

          <div class="flex-1">
            <FormDatepicker
              label-id="requestDate"
              :show-calendar="showRequestDate"
              style="border: 0"
              class="custom-form-control p-0"
              :max="new Date()"
              hide-header
              @show="showRequestDate = true"
              @hidden="showRequestDate = false"
              v-model="requestDate"
            />

            <FormError
              v-if="$v.requestDate.$error && !$v.requestDate.required"
              message="Fecha de solicitud es requerida"
            />
          </div>
        </div>

        <div class="form-group d-flex flex-column flex-md-row">
          <label for="reasonForCancellation" class="label"
          >Motivo de anulación *</label
          >

          <div class="flex-1">
            <input
              id="reasonForCancellation"
              type="text"
              v-model.trim="reasonForCancellation"
              autocomplete="off"
              maxlength="255"
              class="custom-form-control"
              :class="{
                'custom-form-control-error': $v.reasonForCancellation.$error,
              }"
            />
            <FormError
              v-if="
                $v.reasonForCancellation.$error &&
                !$v.reasonForCancellation.required
              "
              message="Motivo de anulación es requerido"
            />
          </div>
        </div>

        <div class="form-group d-flex flex-column flex-md-row">
          <label for="requestedBy" class="label">Solicitado por *</label>

          <div class="flex-1">
            <input
              id="requestedBy"
              type="email"
              placeholder="Ingresar email"
              v-model.trim="requestedBy"
              autocomplete="off"
              class="custom-form-control"
              :class="{
                'custom-form-control-error': $v.requestedBy.$error,
              }"
            />
            <FormError
              v-if="$v.requestedBy.$error && !$v.requestedBy.required"
              message="Email es requerido"
            />
            <FormError
              v-if="$v.requestedBy.$error && !$v.requestedBy.email"
              message="Ingrese un correo electrónico válido"
            />
          </div>
        </div>

        <div class="form-group d-flex flex-column flex-md-row">
          <label for="file" class="label">Evidencia *</label>

          <div class="flex-1">
            <FileInput
              class="mt-2"
              id-file="file"
              :acceptFile="['.pdf']"
              @onChange="onEvidenceChange"
            />

            <FormError
              v-if="$v.file.$error && !$v.file.required"
              message="Evidencia es requerida"
            />
          </div>
        </div>

        <div class="form-group-buttons text-right">
          <button
            class="button button-light"
            type="button"
            @click.prevent="hideModal()"
          >
            Cancelar
          </button>
          <button class="button button-primary" type="submit">Confirmar</button>
        </div>
      </form>
    </section>
  </b-modal>
</template>
<script>
import {
  FormDatepicker,
  SimpleAlert,
  ModalMixin,
  FormError,
  FileInput,
} from "wize-admin";

import * as moment from "moment";

import { required, email } from "vuelidate/lib/validators";
import { Constants as LocalConstants } from "@/core/utils";

export default {
  name: "ModalPaymentCancel",
  props: {
    paymentId: Number,
  },
  data() {
    return {
      showRequestDate: false,
      requestDate: new Date(),
      reasonForCancellation: null,
      requestedBy: null,
      file: null,
    };
  },
  mixins: [ModalMixin],
  validations: {
    reasonForCancellation: {required},
    requestDate: {required},
    file: {required},
    requestedBy: {required, email},
  },
  components: {
    FormDatepicker,
    SimpleAlert,
    FormError,
    FileInput,
  },
  methods: {
    confirm() {
      this.$v.$touch();
      if (this.$v.$error) return;
      this.$emit("confirm", this.paymentId, "¿Desea anular el pago?", {
        status: LocalConstants.PaymentStatus.CANCELED.key,
        cancellationRequestDate: moment(this.requestDate).format("YYYY-MM-DD"),
        cancellationReason: this.reasonForCancellation,
        cancellationFile: this.file,
        cancellationRequestedBy: this.requestedBy
      });
      this.hideModal();
    },
    hideModal() {
      this.requestDate = new Date();
      this.reasonForCancellation = null;
      this.requestedBy = null;
      this.file = null;
      this.$v.file.$reset();
      this.$emit("hide");
    },
    onEvidenceChange(files) {
      this.$v.file.$reset();
      if (Array.isArray(files)) {
        this.file = files[0];
      } else {
        this.file = files;
      }
    },
  },
};
</script>

<style lang="stylus">
@import "~@/core/styles/components/modal-payment-cancel.styl";
</style>
