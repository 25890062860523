<template>
  <div class="voucher page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cargar comprobante</h1>
    </div>

    <div>
      <b-card tag="article" no-body class="custom-card">
        <b-card-header
          header-tag="section"
          class="custom-card-header d-flex align-items-center"
        >
          <h3>Carga de archivos</h3>
        </b-card-header>
        <b-card-body class="custom-card-body p-0">
          <form class="custom-form" @submit.prevent="submit" novalidate>
            <div class="form-section">
              <div class="form-group">
                <label for="identifierType">RUC * </label>
                <input
                    id="companyId"
                    maxlength="11"
                    type="text"
                    v-model.trim="companyId"
                    autocomplete="off"
                    placeholder="Ingresar RUC"
                    class="custom-form-control"
                    :class="{
                      'custom-form-control-error': $v.companyId.$error,
                    }"
                  />
                  <FormError
                    v-if="
                      $v.companyId.$error &&
                      !$v.companyId.required
                    "
                    message="RUC es requerido"
                  />
                  <FormError
                    v-if="
                      $v.companyId.$error && !$v.companyId.numeric
                    "
                    message="RUC debe tener un valor numérico"
                  />
                  <FormError
                    v-if="
                      $v.companyId.$error &&
                      !$v.companyId.minLength
                    "
                    message="RUC debe tener 11 dígitos"
                  />
              </div>
              <div class="form-group">
                  <label for="companyId">Razón social *</label>
                  <input
                    id="companyName"
                    maxlength="200"
                    disabled
                    type="text"
                    v-model.trim="companyName"
                    autocomplete="off"
                    placeholder="Razón social"
                    class="custom-form-control"
                  />
                </div>
              <div class="form-group">
                <label for="identifierType">Tipo de comprobante * </label>
                <FormSelect
                  id="identifierType"
                  v-model="voucherType"
                  :items="voucherTypes"
                  defaultOption="Elegir tipo de comprobante"
                  :showError="$v.voucherType.$error && !$v.voucherType.required"
                />
                <FormError
                  v-if="$v.voucherType.$error && !$v.voucherType.required"
                  message="Tipo de comprobante es requerido"
                />
              </div>
              <div class="form-group">
                <label for="identifierType">Empresa * </label>
                <FormSelect
                  id="identifierType"
                  v-model="businessId"
                  :items="businesses"
                  defaultOption="Elegir empresa"
                  :showError="$v.businessId.$error && !$v.businessId.required"
                />
                <FormError
                  v-if="$v.businessId.$error && !$v.businessId.required"
                  message="Empresa es requerida"
                />
              </div>
              <div class="form-group">
                <label for="paymentDate" class="mb-0">Fecha de comprobante *</label>
                <FormDatepicker
                  label-id="paymentDate"
                  :show-calendar="showIssueDate"
                  style="border: 0"
                  class="custom-form-control px-0"
                  :max="new Date()"
                  @show="showIssueDate = true"
                  @hidden="showIssueDate = false"
                  v-model="issueDate"
                />
                <FormError
                  v-if="$v.issueDate.$error && !$v.issueDate.required"
                  message="Fecha de comprobante es requerido"
                />
              </div>
              <div class="form-group">
                <label for="voucherCode">Número de comprobante *</label>
                <input
                  id="voucherCode"
                  type="text"
                  maxlength="11"
                  v-model.trim="voucherCode"
                  autocomplete="off"
                  placeholder="Ingresar número de comprobante"
                  class="custom-form-control"
                  :class="{
                    'custom-form-control-error': $v.voucherCode.$error,
                  }"
                />
                <FormError
                  v-if="$v.voucherCode.$error && !$v.voucherCode.required"
                  message="Número de comprobante es requerido"
                />
                <FormError
                  v-if="$v.voucherCode.$error && !$v.voucherCode.voucherCodeFormat"
                  message="Número de comprobante no válido, e.g. F001-0001"
                />
              </div>
              <div class="form-group custom-form">
                <label for="total">Importe total del comprobante *</label>
                <input
                  id="total"
                  type="text"
                  maxlength="11"
                  v-model.trim="total"
                  autocomplete="off"
                  placeholder="Ingresar total"
                  class="custom-form-control"
                  :class="{
                    'custom-form-control-error': $v.total.$error,
                  }"
                />
                <FormError
                  v-if="$v.total.$error && !$v.total.required"
                  message="Total es requerido"
                />
                <FormError
                  v-if="$v.total.$error && !$v.total.decimal"
                  message="Ingrese un monto válido"
                />
              </div>
              <div class="form-group">
                <label for="description"
                  >Detalle de producto / servicio *</label
                >
                <textarea
                  id="description"
                  type="text"
                  v-model.trim="description"
                  autocomplete="off"
                  maxlength="200"
                  class="custom-form-control"
                  :class="{
                    'custom-form-control-error': $v.description.$error,
                  }"
                />
                <FormError
                  v-if="$v.description.$error && !$v.description.required"
                  message="Detalle de producto / servicio es obligatorio"
                />
              </div>
              <div class="form-group">
                <label for="prefix">Periodo *</label>
                <FormSelect
                  id="period"
                  v-model="period"
                  :items="periods"
                  defaultOption="Seleccione periodo"
                  :showError="$v.period.$error"
                  @change="setProjectTypeList()"
                />
                <FormError
                  v-if="$v.period.$error && !$v.period.required"
                  message="Periodo es requerido"
                />
              </div>
              <div class="form-group">
                <label for="projectType">Proyecto</label>
                <FormSelect
                  id="projectType"
                  v-model="projectTypeId"
                  :items="projectTypes"
                  defaultOption="Seleccione proyecto"
                  @change="setProjectTypeList()"
                />

              </div>
              <div class="form-group">
                <label for="identifier">Identificador *</label>
                <input
                  id="identifier"
                  type="text"
                  disabled
                  v-model.trim="identifier"
                  autocomplete="off"
                  class="custom-form-control"

                />
              </div>
              <div class="form-group" v-if="projectTagsList.length">
                <label for="prefix">Tags *</label>

                <NestedSelect :options="projectTagsList" :showError="showTagsError" :level="1" @selected="setIdentifier($event)"
                              :visible-level="visibleLevel"
                              :key="nestedSelectorKey"/>

                <FormError
                  message="La etiqueta es requerida"
                  v-if="showTagsError"
                />
              </div>
              <div class="form-group">
                <label for="paymentCode">Código de pago</label>
                <input
                  id="paymentCode"
                  type="text"
                  v-model.trim="paymentCode"
                  autocomplete="off"
                  class="custom-form-control"
                />
              </div>
              <div>
                <FileInput
                  :acceptFile="acceptedFiles"
                  id-file="input-files"
                  @onChange="onChange"
                  :multiple="true"
                />
                <form-error
                  message="Debe seleccionar al menos un archivo, como máximo 2"
                  v-if="$v.files.$error"
                ></form-error>
                <div class="form-group mt-4">
                  <label for="comments">Comentarios</label>
                  <textarea
                    id="comments"
                    type="text"
                    v-model.trim="comments"
                    autocomplete="off"
                    maxlength="500"
                    class="custom-form-control"
                  />
                </div>
                <div class="form-group-buttons text-right">
                  <router-link
                    :to="{ name: 'payments-list' }"
                    class="button button-cancel"
                  >
                    Cancelar
                  </router-link>
                  <button class="button button-primary" type="submit">
                    Procesar
                  </button>
                </div>
                <div class="form-errors mt-3" v-if="errors.length > 0">
                  <SimpleAlert
                    type="light-gray"
                    text="Por favor, solucione los siguientes problemas o caso contrario genera una nota de crédito para cargar una nueva factura."
                  >
                    <template slot="icon">
                      <span class="modal-icon mr-2">
                        <i class="ri-error-warning-line"></i>
                      </span>
                    </template>
                  </SimpleAlert>
                  <b
                    ><span class="error">Errores: {{ errors.length }}</span></b
                  ><br />

                  <span
                    v-for="(e, i) in errors"
                    class="error"
                    :key="`${i}-error`"
                    >{{ e }}<br
                  /></span>
                </div>
              </div>
            </div>
          </form>
        </b-card-body>
      </b-card>
    </div>

    <RejectedVoucherModal
      :show="showRejectReasonModal"
      @hide="hideRejectedReasonModal"
      :reject-reason="rejectReason"
    />
  </div>
</template>

<script>
import {
  decimal,
  maxLength,
  minLength,
  required,
  numeric,
} from "vuelidate/lib/validators";
import {
  SimpleAlert,
  FormDatepicker,
  FormError,
  FormSelect,
  FileInput,
  Alert
} from "wize-admin";
import { RejectedVoucherModal } from "@/core/components";

import { Constants as LocalConstants, Directives, Utils } from "@/core/utils";
import { PaymentService, SupplierService, BusinessService, ProjectTypeService } from "@/core/services";
import { mapGetters } from "vuex";
import NestedSelect from "@/modules/Payments/Components/NestedSelect.vue";

export default {
  name: "UploadVoucherPage",
  props: {
    id: String,
  },
  components: {
    NestedSelect,
    SimpleAlert,
    FileInput,
    FormError,
    FormSelect,
    FormDatepicker,
    RejectedVoucherModal,
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
    acceptedFiles: function () {
      let acceptedFiles = [];
      if (
        this.voucherType === LocalConstants.VoucherTypes.eInvoice.key ||
        this.voucherType === LocalConstants.VoucherTypes.eReceipt.key ||
        this.voucherType === LocalConstants.VoucherTypes.eReceiptForFees.key
      ) {
        acceptedFiles.push(".pdf");
        acceptedFiles.push(".xml");
      } else if (
        this.voucherType === LocalConstants.VoucherTypes.manualReceipt.key
      ) {
        acceptedFiles.push(".pdf");
        acceptedFiles.push(".jpg");
        acceptedFiles.push(".jpeg");
        acceptedFiles.push(".png");
      } else {
        acceptedFiles = [".none"];
      }

      return acceptedFiles;
    },
  },
  data() {
    return {
      showRejectReasonModal: false,
      payment: {},
      files: [],
      errors: [],
      periods: [],
      comments: null,
      voucherTypes: [],
      voucherType: null,
      showIssueDate: false,
      issueDate: null,
      voucherCode: null,
      total: null,
      rejectReason: null,
      companyId: null,
      companyName: null,
      supplierId: null,
      description: null,
      businessId: null,
      businesses: [],
      period: null,
      projectTypeId: null,
      projectTypes: [],
      identifier: null,
      paymentCode: null,
      projectTagsList: [],
      showTagsError: false,
      nestedSelectorKey: 0,
      selectedTags: [],
      visibleLevel: 0,
    };
  },
  validations: {
    description: { required },
    businessId: { required },
    period: { required },
    files: {
      minLength: minLength(1),
      maxLength: maxLength(2),
      required,
    },
    voucherType: { required },
    issueDate: {
      required,
    },
    voucherCode: {
      required,
      voucherCodeFormat: Directives.voucherCodeFormat,
    },
    total: {
      required,
      decimal,
    },
    companyId: { required, numeric, minLength: minLength(11) },
  },
  watch: {
    projectTypeId: function () {
      this.nestedSelectorKey++;
      this.identifier = null;
    },
    "companyId": async function (val) {
      this.$v.companyId.$touch();
      if (this.$v.companyId.$error) return;
      try {
        const response = await SupplierService.getByCompanyId(val);
        const supplier = response.payload;
        this.companyName = supplier.companyName;
        this.supplierId = supplier.id;
        const voucherTypes = JSON.parse(supplier.voucherTypes);
        this.voucherTypes = [];
        for (let key in voucherTypes) {
          if (voucherTypes[key]) {
            this.voucherTypes.push(LocalConstants.VoucherTypes[key]);
          }
        }
      } catch (ex) {
        this.companyId = null;
        this.supplierId = null;
        this.companyName = null;
        Alert.error("No se encontró proveedor");
        console.error(ex);
      }
    },
  },
  methods: {
    onChange(files) {
      this.$v.files.$reset();
      this.files = files;
    },
    setProjectTypeList() {
      let projectType = this.projectTypes.find(
        (item) => item.key === this.projectTypeId
      );

      if (projectType && projectType.tags) {
        this.projectTagsList = projectType.tags;
        this.setLevels(this.projectTagsList);
      } else {
        this.projectTagsList = [];
      }
    },
    setLevels(tags, currentLevel = 1) {
      tags.forEach(tag => {
        tag.level = currentLevel;
        if (tag.children && tag.children.length > 0) {
          this.setLevels(tag.children, currentLevel + 1);
        }
      });
    },
    updateSelection(tags, level) {
      tags.forEach(tag => {
        if (tag.level > level) {
          tag.selected = false;
        }
        if (tag.children && tag.children.length > 0) {
          this.updateSelection(tag.children, level);
        }
      });
    },
    isAnyChildSelected(item) {
      if (!item.children || item.children.length === 0) {
        return item.selected;
      }
      return item.children.some(child => this.isAnyChildSelected(child));
    },
    setIdentifier(e) {
      this.visibleLevel = e.level;
      const existingIndex = this.selectedTags.findIndex(tag => tag.level === e.level);
      this.updateSelection(this.projectTagsList, e.level);
      if (existingIndex !== -1) {
        this.selectedTags[existingIndex] = {level: e.level, key: e.key};
      } else {
        this.selectedTags.push({level: e.level, key: e.key});
      }
      this.selectedTags = this.selectedTags.filter(tag => tag.level <= e.level);
      this.identifier = this.selectedTags.map(tag => tag.key).join('-');
      this.showTagsError = false;
    },
    async submit() {
      this.showTagsError = false
      this.$v.$touch();

      if (this.projectTagsList.length) {
        // Validar que se hayan seleccionado todas las opciones de "tags"
        let selectedTag = this.projectTagsList.find(
          (item) => item.selected
        );

        if (!selectedTag) {
          this.showTagsError = true
          return
        }

        let anyChildSelected = this.isAnyChildSelected(selectedTag);
        this.showTagsError = !anyChildSelected;
      }

      if (this.$v.$anyError || this.showTagsError) {
        return;
      }

      await this.$store.dispatch("app/loading", true);
      this.errors = [];
      const data = {
        files: this.files,
        type: LocalConstants.VoucherTypes[this.voucherType].value,
        total: this.total,
        voucherCode: this.voucherCode,
        issueDate: this.issueDate,
        description: this.description,
        comments: this.comments,
        businessId: this.businessId,
        supplierId: this.supplierId,
        period: this.period,
        projectTypeId: this.projectTypeId,
        identifier: this.identifier,
        paymentCode: this.paymentCode,
      };
      try {
        const resp = await PaymentService.uploadVoucher(data);
        if (resp.payload.messages && resp.payload.messages.length > 0) {
          this.rejectReason = resp.payload.messages.join("<br>");
          this.showRejectReasonModal = true;
          return;
        }
        Alert.success("Documentos cargados correctamente");
        this.$router.push({ name: "payments-list" });
      } catch (e) {
        console.error(e);
        this.errors = [];
        if (e.errors && e.errors.message) this.errors.push(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    hideRejectedReasonModal() {
      this.showRejectReasonModal = false;
      this.$router.push({ name: "payments-list" });
    },
  },
  async created() {
    const businessList = (await BusinessService.list()).payload;
      this.businesses = [];
      businessList.map((business) => {
        this.businesses.push({ key: business.id, label: business.name });
      });

    this.periods = Utils.generatePeriods();

    const projectTypes = (await ProjectTypeService.listActive()).payload;
    projectTypes.forEach((b) => {
        return this.projectTypes.push({
          key: b.id,
          label: b.description,
          paymentFrequency: b.paymentFrequency,
          tags: JSON.parse(b.tags),
        })
      }
    );
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/voucher.styl';
</style>
