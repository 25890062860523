<template>
  <div>
    <div class="dashboard-banner">
      <h1 class="dashboard-title">Datos de la carga</h1>
    </div>

    <div class="card-details">
      <section class="section">
        <h2 class="subtitle my-0">Datos</h2>

        <div>
          <div class="row">
            <div class="col-md-6">
              <h3 class="label">Fecha de carga</h3>
              <p class="text">
                {{ batch.uploadDate | localDate }}
              </p>
            </div>

            <div class="col-md-6">
              <h3 class="label">N° de planilla</h3>
              <p class="text">
                {{ batch.payrollNumber }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h3 class="label">Descripción de planilla</h3>
              <p class="text">
                {{ batch.payrollDesc }}
              </p>
            </div>
            <div class="col-md-6">
              <h3 class="label">Tipo de planilla</h3>
              <p class="text">
                {{ batch.createdBy }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h3 class="label">Fecha y hora de envío</h3>
              <p class="text">
                {{ batch.createdAt | localDate }}
              </p>
            </div>
            <div class="col-md-6">
              <h3 class="label">Cuenta de cargo</h3>
              <p class="text">
                {{ batch.chargedAccount | '-' }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h3 class="label">Abonos enviados</h3>
              <p class="text">
                {{ batch.paymentsSent | '-' }}
              </p>
            </div>
            <div class="col-md-6">
              <h3 class="label">Moneda</h3>
              <p class="text">
                {{ batch.currency || '-' }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h3 class="label">Importe inicial</h3>
              <p class="text" v-decimal:pen="batch.initialAmount"></p>
            </div>
            <div class="col-md-6">
              <h3 class="label">Número de operación</h3>
              <p class="text">
                {{ batch.payrollMovement || '-' }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h3 class="label">Abonos no procesados</h3>
              <p class="text">
                {{ batch.unprocessedPayments || '-' }}
              </p>
            </div>
            <div class="col-md-6">
              <h3 class="label">Importe no abonado</h3>
              <p class="text">
                {{ batch.uncreditedAmount || '-' }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h3 class="label">Total cargado</h3>
              <p class="text" v-decimal:pen="batch.totalCharged"></p>
            </div>
            <div class="col-md-6">
              <h3 class="label">Cargado por</h3>
              <p class="text">
                {{ batch.createdBy }}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="section mb-0 pb-0 border-bottom-0">
        <h2 class="subtitle">Detalle</h2>

        <div>
          <table class="custom-table detail-table">
            <thead>
              <tr>
                <th class="pl-4">Código de pago</th>
                <th>Tipo de documento</th>
                <th class="px-3">Número de documento del proveedor</th>
                <th class="pr-4">Monto abonado</th>
                <th class="pr-4">Estado</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="o in batch.records" :key="o.id">
                <td class="text-center">{{ o.paymentCode }}</td>
                <td class="text-center">
                  {{ getIdentifierType(o.identifierType).label }}
                </td>
                <td class="text-center">{{ o.identifier }}</td>
                <td class="text-center" v-decimal:pen="o.amount"></td>
                <td class="text-center">
                  <span :class="`badge ${getStatus(o.status).color}`">{{
                    o.situation
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row buttons-container d-flex align-items-center mb-0">
          <a
            href=""
            class="download-link"
            v-if="$can('manage', 'pagos_abonos_descarga') && batch.file"
            @click.prevent="download(batch.file)"
          >
            <i class="ri-download-2-line text-primary font-weight-bold"></i>
            <span>Descargar constancias</span>
          </a>

          <div class="col text-right">
            <router-link
              :to="{ name: 'payments-made-batch-list' }"
              class="button button-light"
            >
              Volver
            </router-link>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Alert, Util } from "wize-admin";
import { Constants as LocalConstants } from "@/core/utils";
import { PaymentMadeBatchService } from "@/core/services";

export default {
  data() {
    return {
      batch: {},
    };
  },
  methods: {
    getStatus(key) {
      const value = Util.searchValue(
        key,
        LocalConstants.PaymentMadeRecordStatus
      );
      return value ? value : {};
    },
    getIdentifierType(key) {
      const value = Util.searchValue(key, LocalConstants.IdentifierType);
      return value ? value : {};
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await PaymentMadeBatchService.get(id);
        const payload = response.payload;
        this.batch = { ...payload };
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
        this.$router.push({ name: "supplier-list" });
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async download(asset) {
      try {
        if (asset) {
          this.$store.dispatch("app/loading", true);

          await Util.downloadFile(asset.url, asset.name);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped>
@import '~wize-admin/dist/styles/variables'
@import '~wize-admin/dist/styles/mixins';
  .detail-table
    max-width: 93%
    margin: 24px 40px 0 40px
    border: 1px solid color-gray-4 !important
</style>
