<template>
  <div>
    <div class="dashboard-banner">
      <h1 class="dashboard-title">Motivo de anulación N° {{ payment.code }}</h1>
    </div>

    <div class="card-details">
      <section class="section border-0 mb-0 pb-0">
        <h2 class="subtitle my-0">Información de la anulación</h2>

        <div>
          <div class="row">
            <div class="col-md-12">
              <h3 class="label">Fecha de solicutud</h3>
              <p class="text" v-local-date="payment.cancellationRequestDate"></p>
            </div>

            <div class="col-md-12">
              <h3 class="label">Motivo de anulación</h3>
              <p class="text">{{ payment.cancellationReason }}</p>
            </div>

            <div class="col-md-12">
              <h3 class="label">Solicitado por</h3>
              <p class="text">{{ payment.cancellationRequestedBy }}</p>
            </div>

            <div class="col-md-12">
              <h3 class="label">Evidencia</h3>
              <div class="mt-2" v-if="payment.cancellationFile">
                <a
                  :href="payment.cancellationFile.url"
                  type="button"
                  target="_blank"
                  class="bg-transparent border-0 text-primary"
                >
                  <i class="ri-download-2-line"></i>

                  {{ payment.cancellationFile.name }}
                </a>
                </div>
                <span v-else>--</span>
            </div>
          </div>

          <div class="row buttons-container mb-0">
            <div class="col text-right">
              <router-link
                :to="{ name: 'payments-list' }"
                class="button button-light"
              >
                Volver
              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Alert } from "wize-admin";
import { PaymentService } from "@/core/services";

export default {
  data() {
    return {
      payment: {
        code: "",
      },
    };
  },
  methods: {
    async load(paymentId) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await PaymentService.getPayment(paymentId);
        const payload = response.payload;
        this.payment = { ...payload };
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
        this.$router.push({ name: "payment-list" });
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    this.load(this.$route.params.id);
  },
};
</script>

<style lang="stylus" scoped>
</style>
